'use strict';

angular.module('kljDigitalLibraryApp')
    .config(["$stateProvider", function($stateProvider) {
        $stateProvider
            .state('main', {
                url: '/main',
                templateUrl: 'app/main/main.html',
                controller: 'MainCtrl',
                controllerAs: 'main'
            })

        .state('main.orderSummary', {
            url: '/order_summary?status&ordernumber',
            templateUrl: 'app/tpl/order_summary.html',
            controller: 'MainCtrl',
            controllerAs: 'main'
        })

        .state('main.web_edition', {
            url: '/web_edition',
            templateUrl: 'app/subscription/guest/subscriptions.html',
            controller: 'MainCtrl',
            controllerAs: 'main'
        })

        .state('main.access_code', {
            url: '/access_code?accesscode',
            templateUrl: 'app/subscription/guest/accesscode.details.html',
            // controller: 'AccesscodeCtrl'
            controller: 'MainCtrl',
            controllerAs: 'main'
        })

        .state('main.cd-rom_edition', {
            url: '/cd-rom_edition',
            templateUrl: 'app/subscription/guest/subscriptions.html',
            controller: 'MainCtrl',
            controllerAs: 'main'
        })

        .state('main.trial_version', {
            url: '/trial_version',
            templateUrl: 'app/subscription/guest/subscriptions.html',
            controller: 'MainCtrl',
            controllerAs: 'main'
        })
        .state('main.privacy_policy', {
            url: '/privacy_policy',
            templateUrl: 'app/privacy/privacy.html',
            controller: 'MainCtrl',
            controllerAs: 'main'
        })
        .state('main.terms_conditions', {
            url: '/terms_conditions',
            templateUrl: 'app/terms/terms.html',
            controller: 'MainCtrl',
            controllerAs: 'main'
        })
        .state('main.user_guide', {
            url: '/user_guide',
            templateUrl: 'app/user-guide/userguide.html',
            controller: 'MainCtrl',
            controllerAs: 'main'
        });
    }]);